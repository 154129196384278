var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-data-table', {
    staticClass: "table-section text-no-wrap",
    attrs: {
      "headers": _vm.columns,
      "items": _vm.items,
      "options": _vm.options,
      "server-items-length": _vm.itemsTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": item.status === 'approved' ? 'success' : 'warning'
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(item.status)) + " ")])];
      }
    }, {
      key: "item.questionnaire",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('questionnaire-detail-dialog', {
          attrs: {
            "questionnaire": item.questionnaire
          }
        })];
      }
    }, {
      key: "item.distributor",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.status === 'approved' && item.distributor ? _c('div', [_c('router-link', {
          staticClass: "font-weight-medium text-decoration-none",
          attrs: {
            "to": {
              name: 'distributor-view',
              params: {
                id: item.distributor.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.distributor.id) + " ")])], 1) : _c('div', [_vm._v(" / ")])];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.formatDate(item.created_at)))])];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }