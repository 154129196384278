import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/distributor-registrations', {
    params,
  })
// const show = id => axios.get(`/admin/distributor-registrations/${id}`)
// const store = data => axios.post('/admin/distributor-registrations', data)
// const update = (id, data) => axios.put(`/admin/distributor-registrations/${id}`, data)
// const destroy = id => axios.delete(`/admin/distributor-registrations/${id}`)

export default {
  // destroy,
  // show,
  index,
  // store,
  // update,
}
