var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('span', [_vm._v("Click Me")])])];
      }
    }]),
    model: {
      value: _vm.isDialogVisible,
      callback: function callback($$v) {
        _vm.isDialogVisible = $$v;
      },
      expression: "isDialogVisible"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('Questionnaire')) + " ")]), _c('v-card-text', [_c('v-container', _vm._l(_vm.questionnaire, function (answers, question) {
    return _c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('strong', [_vm._v(_vm._s(_vm.parseQuestion(question)))]), _vm._v(": "), _c('ul', _vm._l(answers, function (answer) {
      return _c('li', [_vm._v(" " + _vm._s(answer) + " ")]);
    }), 0)])], 1);
  }), 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isDialogVisible = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }