<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <div v-bind="attrs"
        v-on="on">
        <span>Click Me</span>
      </div>
    </template>

    <v-card>
      <v-card-title>
        {{ $t('Questionnaire') }}
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row v-for="(answers, question) in questionnaire">
            <v-col cols="12">
              <strong>{{ parseQuestion(question) }}</strong>:

              <ul>
                <li v-for="answer in answers">
                  {{ answer }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          @click="isDialogVisible = false"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  props: {
    questionnaire: {
      type: Object,
      default: () => null,
    },
  },

  setup() {
    const isDialogVisible = ref(false)

    const parseQuestion = question => {
      question = question.replace(/_/g, ' ')

      return question.charAt(0).toUpperCase() + question.slice(1)
    }

    return {
      isDialogVisible,

      parseQuestion,
    }
  },
}
</script>
