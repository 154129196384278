<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <!-- Status -->
      <template #[`item.status`]="{ item }">
        <v-chip
          small
          :color="item.status === 'approved' ? 'success' : 'warning'"
        >
          {{ item.status }}
        </v-chip>
      </template>

      <!-- Status -->
      <template #[`item.status`]="{ item }">
        <v-chip
          small
          :color="item.status === 'approved' ? 'success' : 'warning'"
        >
          {{ $t(item.status) }}
        </v-chip>
      </template>

      <!-- Questionnaire -->
      <template #[`item.questionnaire`]="{ item }">
        <questionnaire-detail-dialog
          :questionnaire="item.questionnaire"
        />
      </template>

      <!-- Distributor -->
      <template #[`item.distributor`]="{ item }">
        <div v-if="item.status === 'approved' && item.distributor">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'distributor-view', params: { id: item.distributor.id } }"
          >
            {{ item.distributor.id }}
          </router-link>
        </div>

        <div v-else>
          /
        </div>
      </template>

      <!-- date -->
      <template #[`item.created_at`]="{item}">
        <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiDelete, mdiDotsVertical } from '@mdi/js'
import QuestionnaireDetailDialog from './QuestionnaireDetailDialog.vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  components: {
    QuestionnaireDetailDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { loading, options, paginate, items, itemsTotal, headerprops, loadResource, resource } = props.tableList

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Reward Points', value: 'reward_points' },
      { text: 'Questionnaire', value: 'questionnaire' },
      { text: 'Status', value: 'status' },
      { text: 'Distributor ID', value: 'distributor' },
      { text: 'Created At', value: 'created_at' },
    ]

    return {
      t,
      dayjs,
      formatDate,

      resource,
      loadResource,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      icons: {
        mdiDotsVertical,
        mdiDelete,
      },
    }
  },
}
</script>
