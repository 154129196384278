var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('filter-section', {
    staticClass: "mb-3",
    attrs: {
      "cols": [3, 3]
    }
  }, [_c('search-filter', {
    attrs: {
      "slot": "col_0"
    },
    slot: "col_0",
    model: {
      value: _vm.filters,
      callback: function callback($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  })], 1), _c('v-card', [_c('v-card-text', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.paginate
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiRefresh) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('common.list.refresh')) + " ")])], 1)], 1)]), _c('registration-table', {
    ref: "tableRef",
    attrs: {
      "table-list": _vm.tableList
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }